<template>
  <div>
    <NavigationBar titel="Stunden melden" showBackBtn></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createTimeSheet"
        ref="form"
        v-model="helpers.timeSheetCreationDialog.formIsValid"
      >
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <template>
                <v-stepper v-model="helpers.stepperState" vertical>
                  <v-stepper-step :complete="helpers.stepperState > 1" step="1">
                    Meldungsart auswählen
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-card outlined>
                      <v-card-text>
                        <v-item-group v-model="timeSheet.type">
                          <v-row class="match-height">
                            <v-col
                              v-for="timeSheetFormType in timeSheetFormTypes"
                              :key="timeSheetFormType.id"
                              cols="12"
                              md="6"
                            >
                              <v-item
                                v-slot="{ active, toggle }"
                                :value="timeSheetFormType.id"
                              >
                                <v-card
                                  outlined
                                  @click="toggle"
                                  :style="
                                    active
                                      ? `border: 3px solid #1867c0 !important`
                                      : ''
                                  "
                                >
                                  <v-list-item>
                                    <v-list-item-icon>
                                      <data-avatar
                                        :icon="timeSheetFormType.icon"
                                        :color="timeSheetFormType.color"
                                      ></data-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="font-weight-medium"
                                      >
                                        {{ timeSheetFormType.title }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          timeSheetFormType.description
                                        }}</v-list-item-subtitle
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-item-group>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          depressed
                          color="primary"
                          :disabled="!timeSheet.type"
                          @click="helpers.stepperState = 2"
                        >
                          Weiter
                          <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-step
                    v-if="timeSheet.type"
                    :complete="helpers.stepperState > 2"
                    step="2"
                  >
                    Daten erfassen
                  </v-stepper-step>

                  <v-stepper-content v-if="timeSheet.type" step="2">
                    <v-card outlined class="">
                      <v-card-subtitle
                        ><v-icon left small class="mr-2">mdi-card-text</v-icon
                        >Allgemein</v-card-subtitle
                      >
                      <v-card-text>
                        <v-row>
                          <!-- <v-col cols="12">
                            <v-autocomplete
                              v-model="timeSheet.user"
                              :items="memberships"
                              outlined
                              hide-details="auto"
                              multiple
                              return-object
                              label="Personen auswählen"
                              item-text="displayName"
                              item-value="uid"
                              :rules="[rules.required]"
                            ></v-autocomplete>
                          </v-col> -->
                          <v-col cols="12">
                            <span
                              v-if="
                                timeSheetTitleTemplates.length &&
                                timeSheet.type === 'otherTime'
                              "
                              >Vorschläge:</span
                            >
                            <v-chip-group
                              v-if="
                                timeSheetTitleTemplates.length &&
                                timeSheet.type === 'otherTime'
                              "
                              v-model="timeSheet.title"
                              active-class="primary--text"
                              show-arrows
                            >
                              <v-chip
                                v-for="(item, index) in timeSheetTitleTemplates"
                                :key="index"
                                label
                                :value="item"
                                class="transparent-background font-weight-medium mr-2 mb-3"
                                >{{ item }}
                              </v-chip>
                            </v-chip-group>
                            <v-text-field
                              v-if="timeSheet.type === 'otherTime'"
                              v-model="timeSheet.title"
                              outlined
                              hide-details="auto"
                              label="Kurzer Tätigkeitstitel"
                              counter
                              :disabled="!timeSheet.type === 'otherTime'"
                              :rules="[rules.requiredIfOther, rules.max20Chars]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn depressed @click="setNow('start')"
                              ><v-icon left>mdi-clock-in</v-icon>Jetzt
                              einsetzen</v-btn
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="timeSheet.workedDuration.start.date"
                              type="date"
                              label="Startdatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required]"
                              required
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="timeSheet.workedDuration.start.time"
                              label="Startzeit"
                              hint="HH:MM"
                              :rules="[rules.required]"
                              outlined
                              type="time"
                              prepend-inner-icon="mdi-clock-start"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="auto">
                            <v-btn depressed @click="setNow('end')"
                              ><v-icon left>mdi-clock-in</v-icon>Jetzt
                              einsetzen</v-btn
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="timeSheet.workedDuration.end.date"
                              type="date"
                              label="Enddatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required]"
                              required
                              prepend-inner-icon="mdi-calendar-end"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="timeSheet.workedDuration.end.time"
                              label="Endzeit"
                              hint="HH:MM"
                              outlined
                              :rules="[rules.required]"
                              type="time"
                              prepend-inner-icon="mdi-clock-end"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-subtitle v-if="timeSheet.type === 'dutyTime'"
                        ><v-icon left small class="mr-2">mdi-fire-truck</v-icon
                        >Einsatzdienst</v-card-subtitle
                      >
                      <v-card-text v-if="timeSheet.type === 'dutyTime'">
                        <v-row>
                          <!-- DUTY -->
                          <v-col cols="12" sm="6" md="6">
                            <v-radio-group
                              v-model="
                                timeSheet.additionalData.location.isOwnStation
                              "
                              label="Standort"
                              mandatory
                              hide-details="auto"
                              class="mt-0 pt-0"
                              :row="$vuetify.breakpoint.xs ? false : true"
                            >
                              <v-radio
                                label="Eigener Standort"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                label="Fremder Standort"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>

                          <v-col
                            v-if="
                              !timeSheet.additionalData.location.isOwnStation
                            "
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-text-field
                              v-model.trim="
                                timeSheet.additionalData.location.name
                              "
                              outlined
                              hide-details="auto"
                              label="Standortbezeichnung"
                              persistent-hint
                              hint="Bspw. FW 9900, FF 9901"
                              prepend-inner-icon="mdi-map-marker"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-if="
                                timeSheet.additionalData.location.isOwnStation
                              "
                              v-model="
                                timeSheet.additionalData.assignedPosition.unit
                              "
                              :items="units"
                              outlined
                              hide-details="auto"
                              item-text="title"
                              item-value="id"
                              return-object
                              label="Einsatzmittel"
                              prepend-inner-icon="mdi-fire-truck"
                              :rules="[rules.required]"
                            ></v-select>
                            <v-text-field
                              v-else
                              v-model="
                                timeSheet.additionalData.assignedPosition.unit
                                  .title
                              "
                              outlined
                              hide-details="auto"
                              label="Einsatzmittel"
                              :rules="[rules.required]"
                              persistent-hint
                              hint="Bspw. LHF 9901/3, RTW 9900/1"
                              prepend-inner-icon="mdi-fire-truck"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="
                                timeSheet.additionalData.assignedPosition
                                  .position
                              "
                              :items="positions"
                              outlined
                              hide-details="auto"
                              item-text="title"
                              item-value="id"
                              return-object
                              label="Funktion"
                              prepend-inner-icon="mdi-tag"
                              :rules="[rules.required]"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-subtitle v-if="timeSheet.type === 'courseTime'"
                        ><v-icon left small class="mr-2">mdi-school</v-icon
                        >Lehrgang/Fortbildung</v-card-subtitle
                      >
                      <v-card-text v-if="timeSheet.type === 'courseTime'">
                        <v-row>
                          <!-- COURSES -->
                          <v-col cols="12" sm="6">
                            <v-select
                              v-model="timeSheet.additionalData.course"
                              label="Lehrgang"
                              :items="availableCourses"
                              item-text="title"
                              return-object
                              clearable
                              hint="Lehrgang aus Liste wählen"
                              persistent-hint
                              no-data-text="Keine Lehrgänge vorhanden. Lehrgang manuell im Feld 'Externe Bezeichung' eintragen"
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model.trim="
                                timeSheet.additionalData.externalName
                              "
                              label="Externe Bezeichnung"
                              hint="z.B. Kurs-/Lehrgangsbezeichnung"
                              persistent-hint
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model.trim="timeSheet.additionalData.externalId"
                              label="Kurs-/Lehrgangsnummer"
                              :rules="[rules.required]"
                              hide-details="auto"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="timeSheet.additionalData.proofDate"
                              label="Nachweisdatum"
                              type="date"
                              hint="Datum des Leistungsnachweises/Urkunde"
                              persistent-hint
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="timeSheet.additionalData.rating"
                              label="Bewertung"
                              :items="coursesRatingOptions"
                              :rules="[rules.required]"
                              type="date"
                              hide-details="auto"
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            Nachweise
                            <file-upload-dropzone
                              v-model="timeSheet.additionalData.filesToUpload"
                              :multiple="true"
                              :acceptedFileTypes="[
                                'image/*',
                                'application/pdf',
                              ]"
                            ></file-upload-dropzone>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="timeSheet.description"
                              label="Hinweise/Bemerkungen"
                              hide-details="auto"
                              outlined
                              :rows="3"
                              auto-grow
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          depressed
                          color="primary"
                          :disabled="
                            !helpers.timeSheetCreationDialog.formIsValid
                          "
                          @click="helpers.stepperState = 3"
                        >
                          Weiter
                          <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-step
                    v-if="timeSheet.type"
                    :complete="helpers.stepperState > 3"
                    step="3"
                  >
                    Daten prüfen
                  </v-stepper-step>

                  <v-stepper-content v-if="timeSheet.type" step="3">
                    <v-card flat>
                      <v-card-text>
                        <v-row class="match-height"> </v-row>
                        <v-row class="mt-8">
                          <v-col cols="12">
                            <v-alert text color="primary" type="info">
                              <div class="title">Hinweis</div>
                              <div class="mt-2">
                                Erstellte Stundenmeldungen werden einem
                                Genehmigungsprozess übergeben. Der aktuelle
                                Status des Vorgangs kann jederzeit über den
                                Datensatz eingesehen werden.
                              </div>
                            </v-alert>
                            <v-alert
                              v-if="
                                this.helpers.timeSheetCreationDialog
                                  .errorMessage
                              "
                              text
                              color="error"
                              type="error"
                            >
                              <div class="title">Fehler</div>
                              <div class="mt-2">
                                {{
                                  this.helpers.timeSheetCreationDialog
                                    .errorMessage
                                }}
                              </div>
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          depressed
                          @click="helpers.stepperState = 2"
                          :disabled="helpers.timeSheetCreationDialog.loading"
                        >
                          <v-icon left>mdi-chevron-left</v-icon>Zurück
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="primary"
                          :loading="helpers.timeSheetCreationDialog.loading"
                          @click="createTimeSheet()"
                        >
                          <v-icon left>mdi-ray-start-arrow</v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>
                </v-stepper>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section>
  </div>
</template>

<script>
import { auth, functions, Timestamp, storage, db, TaskEvent } from "@/firebase";
import { ADMIN, MEMBER_MANAGEMENT, AVAILABILITY } from "@/store/modules.js";
import {
  GET_POSITIONS,
  GET_UNITS,
  GET_USER_LIST,
  GET_COURSES,
} from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import FileUploadDropzone from "@/components/_systemwide/FileUploadDropzone.vue";
export default {
  name: "accounting-time-sheets-time-sheet-new",
  components: {
    NavigationBar,
    DataAvatar,
    FileUploadDropzone,
  },
  data() {
    return {
      timeSheetFormTypes: [
        {
          id: "dutyTime",
          title: "Einsatzdienst",
          description: "Zusätzliche Einsatzmittel- und Funktionserfassung",
          icon: "fire-truck",
          color: "#F44336FF",
        },
        {
          id: "courseTime",
          title: "Lehrgang/Fortbildung",
          description: "Zusätzliche Nachweiserfassung",
          icon: "school",
          color: "#009688FF",
        },
        {
          id: "otherTime",
          title: "Sonstige Stunden",
          description: "Allgemeine Erfassung",
          icon: "dots-horizontal-circle",
          color: "#607D8BFF",
        },
      ],
      coursesRatingOptions: [
        { text: "Bestanden", value: "passed" },
        { text: "Nicht bestanden", value: "failed" },
        { text: "Abgebrochen", value: "aborted" },
        { text: "Nicht bewertet", value: "unrated" },
      ],
      timeSheet: {
        type: "",
        title: "",
        description: "",
        additionalData: {
          // dutyTime
          location: {
            isOwnStation: true,
            name: null,
          },
          assignedPosition: {
            unit: { title: "", id: "" },
            position: { shortTitle: "", title: "", id: "" },
          },
          // courseTime
          course: { title: "", id: "" },
          externalName: "",
          proofDate: null,
          rating: "",
          externalId: "",
          files: [],
          filesToUpload: [],
          // otherTime
        },
        workedDuration: {
          start: { date: null, time: null },
          end: { date: null, time: null },
        },
        user: {
          uid: auth.currentUser.uid,
          membershipId: auth.currentUser.uid,
          displayName: auth.currentUser.displayName,
        },
        payrollType: {
          id: null,
          title: null,
        },
        payrollInputVariables: [],
      },
      helpers: {
        stepperState: 1,
        timeSheetCreationDialog: {
          formIsValid: false,
          model: false,
          loading: false,
          errorMessage: "",
          timeSheetFormType: "",
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        requiredIfOther: (value) =>
          (this.timeSheet.type === "otherTime" && !!value) ||
          "Feld ist erforderlich.",
        max20Chars: (value) =>
          (value && value.length <= 20) || "Maximal 20 Zeichen erlaubt.",
        startBeforeEnd: () =>
          (!this.fieldsAreFilled &&
            this.convertToTimestamp(
              this.timeSheet.workedDuration.start.date,
              this.timeSheet.workedDuration.start.time
            ).valueOf() <
              this.convertToTimestamp(
                this.timeSheet.workedDuration.end.date,
                this.timeSheet.workedDuration.end.time
              ).valueOf()) ||
          "Start muss vor Ende liegen",
      },
    };
  },
  watch: {
    "timeSheet.additionalData.station.isOwnStation"(newVal) {
      if (newVal === false) {
        this.timeSheet.additionalData.assignedPosition.unit = {
          title: "",
          id: "",
        };
      }
    },
  },
  computed: {
    timeSheetTitleTemplates() {
      return this.$store.state.organization.activeOrganization.config.accounting
        .timeSheets.titleTemplates;
    },
    fieldsAreFilled() {
      if (
        !this.timeSheet.workedDuration.start.date === "" &&
        !this.timeSheet.workedDuration.start.time === "" &&
        !this.timeSheet.workedDuration.end.date === "" &&
        !this.timeSheet.workedDuration.end.time === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    positions() {
      return [...this.$store.state.availability.positions]
        .sort((a, b) => a.priority - b.priority)
        .map((position) => ({
          title: position.title,
          shortTitle: position.shortTitle,
          id: position.meta.id,
        }));
    },
    units() {
      return [...this.$store.state.availability.units]
        .sort((a, b) => a.priority - b.priority)
        .map((unit) => ({
          title: unit.title,
          id: unit.meta.id,
        }));
    },
    availableCourses() {
      return [...this.$store.state.memberManagement.courses].map((course) => ({
        title: course.title,
        id: course.meta.id,
      }));
    },
    payrollTypes() {
      return this.$store.state.admin.payrollTypes.map((payrollType) => ({
        id: payrollType.meta.id,
        title: payrollType.title,
      }));
    },
    requiredPayrollTypeInputVariables() {
      return this.timeSheet.payrollType
        ? this.timeSheet.payrollType.inputVariables
        : [];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      await this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
        organizationId: this.$route.params.organizationId,
      });
      await this.$store.dispatch(`${AVAILABILITY}${GET_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
      await this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async uploadProofFiles() {
      // Create the file metadata
      var files = this.timeSheet.additionalData.filesToUpload; // Array of files
      var storageRef = storage.ref();
      var uploadedFiles = [];

      for (const file of files) {
        var metadata = {
          contentType: file.type, // Set the correct content type based on the file type
        };

        // Generate a unique document ID using Firestore
        var documentId = db.collection("x").doc().id;

        var fileExtension = file.name.split(".").pop();
        var uploadTask = storageRef
          .child("organizations/")
          .child(this.$route.params.organizationId + "/")
          .child(
            "memberships/" +
              this.timeSheet.user.membershipId +
              "/courseProofs/" +
              documentId +
              "/" +
              "ProofFile." +
              fileExtension
          )
          .put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        await new Promise((resolve, reject) => {
          uploadTask.on(
            TaskEvent.STATE_CHANGED,
            (snapshot) => {
              switch (snapshot.state) {
                case TaskEvent.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case TaskEvent.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            (error) => {
              // Ensure this.helpers.unitImageUpload is defined
              if (!this.helpers.unitImageUpload) {
                this.helpers.unitImageUpload = {};
              }
              this.helpers.unitImageUpload.errorMessage = error;
              console.log(error.message);
              reject(error);
            },
            () => {
              // Get the path of the file and save it to this.timeSheet.additionalData.uploadedFiles
              const filePath = uploadTask.snapshot.ref.fullPath;
              uploadedFiles.push({
                name: file.name,
                path: filePath,
                type: file.type,
                size: file.size,
              });
              if (uploadedFiles.length === files.length) {
                this.timeSheet.additionalData.files = uploadedFiles;
              }
              resolve();
            }
          );
        });
      }
    },
    async createTimeSheet() {
      this.validate();
      if (!this.helpers.timeSheetCreationDialog.formIsValid) {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
        return;
      }

      this.helpers.timeSheetCreationDialog.loading = true;
      this.helpers.timeSheetCreationDialog.errorMessage = "";

      try {
        const timeSheetData = {
          ...this.timeSheet,
          title:
            this.timeSheet.type === "dutyTime"
              ? "Einsatzstunden"
              : this.timeSheet.type === "courseTime"
              ? "Lehrgang"
              : "Zeiteintrag",
          workedDuration: {
            start: {
              timestamp: this.convertToTimestamp(
                this.timeSheet.workedDuration.start.date,
                this.timeSheet.workedDuration.start.time
              ),
            },
            end: {
              timestamp: this.convertToTimestamp(
                this.timeSheet.workedDuration.end.date,
                this.timeSheet.workedDuration.end.time
              ),
            },
          },
        };

        const callFunction = functions.httpsCallable(
          "accounting-createTimeSheet"
        );
        // Validation request before uploading proof files
        const preCheckResponse = await callFunction({
          organizationId: this.$route.params.organizationId,
          timeSheet: timeSheetData,
          isOnlyPreCheck: true,
        });

        if (!preCheckResponse.data.preCheckResult.success) {
          this.helpers.timeSheetCreationDialog.errorMessage =
            "Fehlerhafte Eingabe erkannt";
          return;
        }

        // Upload proof files by calling uploadProofFiles()
        await this.uploadProofFiles();
        // Request time sheet creation
        const creationResponse = await callFunction({
          organizationId: this.$route.params.organizationId,
          timeSheet: timeSheetData,
          isOnlyPreCheck: false,
        });

        const timeSheetId = creationResponse.data;
        this.$router.push({
          name: "accounting-time-sheets-details",
          params: {
            organizationId: this.$route.params.organizationId,
            itemId: timeSheetId,
          },
        });
      } catch (error) {
        this.helpers.timeSheetCreationDialog.errorMessage =
          error.message ||
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.";
        console.error("Error creating time sheet: ", error);
      } finally {
        this.helpers.timeSheetCreationDialog.loading = false;
      }
    },
    setNow(type) {
      const now = new Date();
      const date = now.toISOString().split("T")[0];
      const time = now
        .toTimeString()
        .split(" ")[0]
        .split(":")
        .slice(0, 2)
        .join(":");
      if (type === "start") {
        this.timeSheet.workedDuration.start.date = date;
        this.timeSheet.workedDuration.start.time = time;
      } else if (type === "end") {
        this.timeSheet.workedDuration.end.date = date;
        this.timeSheet.workedDuration.end.time = time;
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
